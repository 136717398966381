// App.js
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col } from "react-bootstrap";
import { useQuery } from "react-query";
import Spinner from "../../../../../../Partials/Spinner";

import { getMenuForAdmin } from '../../../../../../hooks/menu/menu'
import {
    getMenuTemplates,
    // getMenuTemplate,
    // getWeeklyMenus
} from "../../../../../../hooks/admin/superiorAdmin";
// Dagarna i veckan på svenska
const daysOfWeek = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];

// Dessa kan vara inställda av en administratör
const ALT_COUNT_LUNCH = 2;
const ALT_COUNT_SUPPER = 2;

// Hjälpfunktion för att skapa en tom veckostruktur
function createEmptyWeek(weekNumber) {
    return {
        weekNumber,
        days: daysOfWeek.map(day => ({
            day,
            // För varje måltid skapas en array med tomma alt-platser.
            lunch: Array.from({ length: ALT_COUNT_LUNCH }, () => ({ dish: null, sideDish: null })),
            // I Sverige brukar man kalla kvällsmåltiden "Middag"
            supper: Array.from({ length: ALT_COUNT_SUPPER }, () => ({ dish: null, sideDish: null })),
        })),
    };
}

function App() {
    // Inställningar för cykeln
    const [cycleName, setCycleName] = useState('');
    const [loopInterval, setLoopInterval] = useState(6);
    const [isLooping, setIsLooping] = useState(true);
    const [templateId, setTemplateId] = useState('63793934371bc9e6500f982d');console.log(templateId)
    const [templateName, setTemplateName] = useState({
        _id: '63793934371bc9e6500f982d'
    });
    // Veckostatus (varje vecka motsvarar en cykelvecka med dagar & måltider)
    const [weeks, setWeeks] = useState([createEmptyWeek(1)]);
    const [activeWeekIndex, setActiveWeekIndex] = useState(0);
    const { data: menu } = useQuery(['getMenu', templateName], () => getMenuForAdmin(templateName));
    const { data: menuTemplates, error, isLoading } = useQuery("getMenuTemplates", getMenuTemplates);
    // const { data: menuTemplate } = useQuery(["getMenuTemplate", templateId], () => getMenuTemplate(templateId), { enabled: !!templateId });
    // Maträtter som finns att dra in i kalendern.
    const dishes = menu?.data[0]?.menu;
    // Lägg till eller ta bort en vecka i cykeln
    const addWeek = () => {
        const newWeekNumber = weeks.length + 1;
        setWeeks([...weeks, createEmptyWeek(newWeekNumber)]);
    };

    const removeWeek = () => {
        if (weeks.length > 1) {
            setWeeks(weeks.slice(0, weeks.length - 1));
        }
    };

    // Hanterare för när ett drag avslutas.
    const onDragEnd = (result) => {
        const { source, destination, draggableId } = result;
        if (!destination) return; // om droppet sker utanför en droppable

        // Här drar vi från "dishesMenu" in på en kalender-drop-zon.
        if (source.droppableId === 'dishesMenu') {
            // Vårt droppableId för kalenderceller har följande struktur:
            // `week-{weekIndex}-day-{dayIndex}-meal-{mealType}-alt-{altIndex}`
            const parts = destination.droppableId.split('-');
            const weekIndex = parseInt(parts[1], 10);
            const dayIndex = parseInt(parts[3], 10);
            const mealType = parts[5]; // "lunch" eller "supper"
            const altIndex = parseInt(parts[7], 10);

            // Uppdatera veckostatus: sätt in maträttens id i rätt cell.
            setWeeks(prevWeeks => {
                const newWeeks = [...prevWeeks];
                newWeeks[weekIndex].days[dayIndex][mealType][altIndex] = { dish: draggableId, sideDish: null };
                return newWeeks;
            });
        }
    };
    const handleChange = (e) => {
        e.preventDefault()
        const templateName = e.target.value;
        if (!templateName) { return <></> }
        const selectedIndex = e.target.selectedIndex;
        const selectedOptionData = menuTemplates?.data?.templates[selectedIndex - 1];
        setTemplateId(selectedOptionData._id);
        setTemplateName(templateName);
    }
    if (error) return <div>Nånting gick fel</div>;
    if (isLoading)
        return (
            <div style={{ height: '100vh' }}>
                <Spinner />
            </div>
        );
        return (
            <div className="App">
              <h1>Menycykelplanerare</h1>
              <Row className='mb-4'>
                <Col sm={4} lg={4} xl={4}>
                  <div>
                    <select
                      onChange={handleChange}
                      name="template"
                      className="form-select mt-4 mb-4"
                    >
                      <option value={"63793934371bc9e6500f982d"}>Välj mallen</option>
                      {menuTemplates?.data?.templates?.map((item, index) => (
                        <option key={index} value={item.templateName}>
                          {item.templateName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Veckokontroller */}
                  <div className="d-flex">
                    <button className='mainButton p-0 text-light' onClick={removeWeek}>-</button>
                    <div className="text-light text-center font-size-s p-3"> Veckor: {weeks.length} </div>
                    <button className='mainButton p-0 text-light' onClick={addWeek}>+</button>
                  </div>
                </Col>
                <Col sm={8} lg={8} xl={8}>
                <div className='d-flex'>
                  {/* Cykelinställningar */}
                  <div className="input-group">
                    <label>
                      Cykelnamn:
                      <input
                        type="text"
                        value={cycleName}
                        onChange={(e) => setCycleName(e.target.value)}
                      />
                    </label>
                    <label>
                      Loop Intervall:
                      <input
                        type="number"
                        value={loopInterval}
                        onChange={(e) => setLoopInterval(parseInt(e.target.value, 10))}
                      />
                    </label>
                    <label>
                      Loopar:
                      <input
                        type="checkbox"
                        className='p-0 mt-3 ms-3 radio'
                        checked={isLooping}
                        onChange={(e) => setIsLooping(e.target.checked)}
                      />
                    </label>
                  </div>
                </div>
                </Col>
              </Row>
        
              <div className="planner-container">
                <DragDropContext onDragEnd={onDragEnd}>
                  {/* Render each week as a grid row. Clicking the row sets it as active */}
                  {weeks.map((week, weekIndex) => (
                    <div
                      key={week.weekNumber}
                      className={`week-row ${activeWeekIndex === weekIndex ? 'active' : ''}`}
                      onClick={() => setActiveWeekIndex(weekIndex)}
                    >
                      <div className="week">
                        <h5>Vecka {week.weekNumber}</h5>
                        <div className="days">
                          {week.days.map((day, dayIndex) => (
                            <div key={day.day} className="day-cell">
                              <h3>{day.day}</h3>
        
                              {/* Lunch-avsnitt */}
                              <div className="meal-section">
                                <strong>Lunch</strong>
                                {day.lunch.map((slot, altIndex) => (
                                  <Droppable
                                    key={`week-${weekIndex}-day-${dayIndex}-meal-lunch-alt-${altIndex}`}
                                    droppableId={`week-${weekIndex}-day-${dayIndex}-meal-lunch-alt-${altIndex}`}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className="drop-zone"
                                      >
                                        {slot.dish ? <span>{slot.dish}</span> : 'Släpp maträtten här'}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                ))}
                              </div>
        
                              {/* Middag-avsnitt */}
                              <div className="meal-section">
                                <strong>Middag</strong>
                                {day.supper.map((slot, altIndex) => (
                                  <Droppable
                                    key={`week-${weekIndex}-day-${dayIndex}-meal-supper-alt-${altIndex}`}
                                    droppableId={`week-${weekIndex}-day-${dayIndex}-meal-supper-alt-${altIndex}`}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className="drop-zone"
                                      >
                                        {slot.dish ? <span>{slot.dish}</span> : 'Släpp maträtten här'}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* Render the dishes menu only for the active week */}
                      {activeWeekIndex === weekIndex && (
                        <div className="dishes-menu">
                          <h2>Maträtter</h2>
                          <Droppable droppableId="dishesMenu" isDropDisabled={true}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                {dishes?.map((dish, index) => (
                                  <Draggable key={dish._id} draggableId={dish.dishName} index={index}>
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="dish-item"
                                      >
                                        {dish?.dishName}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </div>
                  ))}
                </DragDropContext>
              </div>
            </div>
          );
}

export default App;
