import React from "react";
import NewOrder from "./pages/plan/orders/newOrder";
import NewStats from "./pages/producer/newStats";
import ProductionList from "./pages/producer/components/lunchStats/subKitchen/logbooktables";
import NewStatsReceiver from "./pages/receiver/newStatsReceiver";
import StatsRecordReceiver from "./pages/receiver/components/statsRecordReceiver";
import Workplan from "./pages/plan/workPlan/workPlan";
import StatsRecord from "./pages/producer/components/lunchStats/statsRecord";
import SubKitchen from "./pages/producer/components/lunchStats/subKitchen";
import Vikarieparm from "./pages/receiver/sub_index/vikarieparm/vikarieparm";
import WorkRoutine from "./pages/receiver/sub_index/vikarieparm/workRoutine";
import OrderRoutine from "./pages/plan/orderRoutine/orderRoutine";
import KitchenRoutine from "./pages/plan/workPlan/workRoutine/workRoutine";
import FoodWaste from "./pages/control/foodWaste/foodWaste";
import Message from "./pages/message/Messages";
import DailyMealIngredientsProducer from "./pages/plan/DailyMealIngredient/DailyMealIngredientsProducer";
import DailyMealIngredientsReceiver from "./pages/plan/DailyMealIngredient/DailyMealIngredientsReceiver";
import SpecialKost from "./pages/producer/components/specialKost";
import UnifiedLunchOrders from "./pages/producer/sub_index/lunchOrders";
import LunchOrders from "./pages/receiver/sub_index/lunchOrders";
import KitchenSetting from "./pages/admin/component/kitchenSetting/KitchenSetting";
import DataAnalyze from "./pages/admin/component/DataAnalyze";
import NewUser from "./pages/admin/component/NewUser";
import MultiUserNewStat from "./pages/admin/component/statsRegister";
import QuantityTemplate from "./pages/admin/component/quantityTemplate";
import SpecialkostSetting from "./pages/admin/component/superiorAdmin/Specialkost/specialkostSetting";
import MenuSetting from "./pages/admin/component/superiorAdmin/menuSetting/menuSetting";
import MenuTemplate from "./pages/admin/component/superiorAdmin/menuSetting/menuTemplate";
import SidesTemplate from "./pages/admin/component/superiorAdmin/menuSetting/sidesTemplate";
import DnD from "./pages/admin/component/superiorAdmin/menuSetting/weeklyMenu/DnD";
import DataAnalyzeAdmin from "./pages/admin/component/DataAnalyze";
import NewAdmin from "./pages/admin/component/superiorAdmin/adminSetting/sectionAdminSetting";
import SpecialkostType from "./pages/admin/component/superiorAdmin/Specialkost/specialType";
import LoginTrace from "./pages/admin/component/LoginTrace";

export const routerMaster = [
  {
    path: "/user/newOrder",
    component: <NewOrder />,
    name: "Ny beställning",
    icon: "fa-solid fa-list-check fa-xl",
    layout: "/producer",
    function: "plan"
  },
  {
    path: "/newStats",
    component: <NewStats />,
    name: "Ny Lunch Statistik",
    icon: "fa-regular fa-chart-bar fa-xl",
    layout: "/producer",
    function: "meal"
  },
  {
    path: "/productionList",
    component: <ProductionList />,
    name: "Productions List",
    icon: "fa-solid fa-table-list fa-xl",
    function: "meal",
    layout: "/producer",
  },
  {
    path: "/newStatsReceiver",
    component: <NewStatsReceiver />,
    name: "Ny Lunch Statistik",
    icon: "fa-regular fa-chart-bar fa-xl",
    layout: "/receiver",
    function: "meal"
  },
  {
    path: "/statsRecord",
    component: <StatsRecord />,
    name: "Statistik",
    icon: "fa-solid fa-chart-column fa-xl",
    layout: "/producer",
    function: "meal"
  },
  {
    path: "/statsRecordReceiver",
    component: <StatsRecordReceiver />,
    name: "Statistik",
    icon: "fa-solid fa-chart-column fa-xl",
    function: "meal",
    layout: "/receiver",
  },
  {
    path: "/workplan",
    component: <Workplan />,
    name: "Todos",
    icon: "fa-solid fa-list-ul fa-xl",
    function: "plan",
    layout: "",
  },
  {
    path: "/subKitchen",
    component: <SubKitchen />,
    name: "Mottagningskök",
    icon: "fa-solid fa-kitchen-set fa-xl",
    function: "meal",
    layout: "/producer",
  },
  {
    path: "/Vikarieparm",
    component: <Vikarieparm />,
    name: "Vikariepärm",
    icon: "fa-regular fa-folder-open fa-xl",
    function: "plan",
    layout: "/receiver",
  },
  {
    path: "/WorkRoutine",
    component: <WorkRoutine />,
    name: "Jobb rutine",
    icon: "fa-solid fa-business-time fa-xl",
    function: "plan",
    layout: "/receiver",
  },
  {
    path: "/OrderRoutine",
    component: <OrderRoutine />,
    name: "Beställnings rutine",
    icon: "fa-solid fa-rotate fa-xl",
    function: "plan",
    layout: "",
  },
  {
    path: "/KitchenRoutine",
    component: <KitchenRoutine />,
    name: "Kökets rutine",
    icon: "fa-solid fa-sink fa-xl",
    function: "plan",
    layout: "",
  },
  {
    path: "/FoodWaste",
    component: <FoodWaste />,
    name: "Matsvinn",
    icon: "fa-solid fa-recycle fa-xl",
    function: "control",
    layout: "/receiver",
  },
  {
    path: "/Message",
    component: <Message />,
    name: "Message",
    icon: "fa-solid fa-comment-dots fa-xl",
    function: "control",
    layout: "",
  },
  {
    path: "/DailyMealIngredientsProducer",
    component: <DailyMealIngredientsProducer />,
    name: "DailyMealIngredients",
    icon: "fa-solid fa-utensils fa-xl",
    function: "meal",
    layout: "/producer",
  },
  {
    path: "/DailyMealIngredientsReceiver",
    component: <DailyMealIngredientsReceiver />,
    name: "DailyMealIngredients",
    icon: "fa-solid fa-utensils fa-xl",
    function: "meal",
    layout: "/receiver",
  },
  {
    path: "/SpecialKost_production",
    component: <SpecialKost />,
    name: "SpecialKost",
    icon: "fa-solid fa-wheat-awn-circle-exclamation fa-xl",
    function: "meal",
    layout: "/producer",
  },
  {
    path: "/lunchOrders",
    component: <LunchOrders />,
    name: "Lunch beställning",
    icon: "fa-solid fa-file-pen fa-xl",
    function: "meal",
    layout: "/receiver",
  },
  {
    path: "/unifiedLunchOrders",
    component: <UnifiedLunchOrders />,
    name: "Lunch Beställning",
    icon: "fa-solid fa-file-pen fa-xl",
    function: "meal",
    layout: "/producer",
  },
  {
    path: "/kitchenSetting",
    component: <KitchenSetting />,
    name: "Köksinställning",
    icon: "fa-solid fa-bars-progress fa-xl",
    layout: "/admin",
  },
  {
    path: "/newUser",
    component: <NewUser />,
    name: "Ny Användare",
    icon: "fa-regular fa-user fa-xl",
    layout: "/admin",
  },
  {
    path: "/multiUserNewStat",
    component: <MultiUserNewStat />,
    name: "Enhetlig register",
    function: "meal",
    icon: "fa-solid fa-users-rectangle fa-xl",
    layout: "/admin",
  },
  {
    path: "/quantityTemplate",
    component: <QuantityTemplate />,
    name: "Mängdmallen",
    function: "meal",
    icon: "fa-regular fa-clipboard fa-xl",
    layout: "/admin",
  },
  {
    path: "/dietSetting",
    component: <SpecialkostSetting />,
    name: "Specialkost",
    function: "meal",
    icon: "fa-solid fa-wheat-awn-circle-exclamation fa-xl",
    layout: "/admin",
  },
  {
    path: "/dataAnalyze",
    component: <DataAnalyze />,
    name: "Data analys",
    function: "meal",
    icon: "fa-solid fa-chart-pie fa-xl",
    layout: "/admin",
  },
  {
    path: "/menuSetting",
    component: <MenuSetting />,
    name: "Maträtt inställning",
    function: "meal",
    icon: "fa-solid fa-bars-progress fa-xl",
    layout: "/superiorAdmin",
  },
  {
    path: "/menuTemplate",
    component: <MenuTemplate />,
    name: "Menymall",
    function: "meal",
    icon: "fa-solid fa-file-pen fa-xl",
    layout: "/superiorAdmin",
  },
  {
    path: "/sidesTemplate",
    component: <SidesTemplate />,
    name: "Tillbehör inställning",
    function: "meal",
    icon: "fa-solid fa-bowl-food fa-xl",
    layout: "/superiorAdmin",
  },
  {
    path: "/dndWeeklyMenu",
    component: <DnD />,
    name: "Veckomeny",
    function: "meal",
    icon: "fa-solid fa-calendar-week fa-xl",
    layout: "/superiorAdmin",
  },
  {
    path: "/dataAnalyzeAdmin",
    component: <DataAnalyzeAdmin />,
    name: "Data analys",
    function: "meal",
    icon: "fa-solid fa-chart-pie fa-xl",
    layout: "/superiorAdmin",
  },
  {
    path: "/newAdmin",
    component: <NewAdmin />,
    name: "Ny områdes admin",
    icon: "fa-solid fa-file-pen fa-xl",
    layout: "/superiorAdmin",
  },
  {
    path: "/specialkostType",
    component: <SpecialkostType />,
    name: "Specialkost",
    function: "meal",
    icon: "fa-solid fa-wheat-awn-circle-exclamation fa-xl",
    layout: "/superiorAdmin",
  },
  {
    path: "/loginTrace",
    component: <LoginTrace />,
    name: "LoginTracer",
    icon: "fa-solid fa-shoe-prints fa-xl",
    layout: "/superiorAdmin",
  },
];

