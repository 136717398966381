//jslint(es6)
import { useEffect } from "react";
import { toast } from "react-toastify";
import {useQueryClient} from 'react-query'
const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AppLogout = ({ children }) => {
  let timer;
// this function sets the timer that logs out the user after 10 secs
const handleLogoutTimer = () => {
  timer = setTimeout(() => {
    // clears any pending timer.
    resetTimer();
    // Listener clean up. Removes the existing event listener from the window
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, resetTimer);
    });
    // logs out user
    if(window.location.pathname === "/login" || window.location.pathname === "/"){ return }
    else logoutAction()
    
  }, 3600000); // 10000ms = 10secs. You can change the time.
};

// this resets the timer if it exists.
const resetTimer = () => {
  if (timer) clearTimeout(timer);
};

// when component mounts, it adds an event listeners to the window
// each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 3600000 secs of inactivity resets.
// However, if none of the event is triggered within 3600000 secs, that is app is inactive, the app automatically logs out.
useEffect(() => {
  Object.values(events).forEach((item) => {
    window.addEventListener(item, () => {
      resetTimer();
     handleLogoutTimer()
    });
  });
  // eslint-disable-next-line
}, []);

// logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
const queryClient = useQueryClient()
const logoutAction = () => {
  localStorage.clear();
    queryClient.clear();
    toast("Utloggad");
    window.location.reload()
    window.location.pathname = "/login";
};

  return children;
};

export default AppLogout;